.whatTheySay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 900px;
  margin-top: 70px;
  background-image: url("../../../Assets/images/roof/home/what-thay-sayBckg.png");
  background-size: cover;
  @media only screen and (max-width: 1440px) {
    background-position-x: 50%;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @media only screen and (max-width: 980px) {
    align-items: center;
    text-align: center;
    background-color: #f2f7fe;
    background-image: none;
  }
  .contentSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    @media only screen and (max-width: 1440px) {
      width: 100%;
    }
    .logoGoogle {
      @media only screen and (max-width: 980px) {
        width: 35%;
      }
    }
    h2 {
      margin-bottom: 40px;
      color: #19569c;
      font-weight: 500;
      text-shadow: none;
      width: 80%;
      text-align: center;
      @media only screen and (max-width: 980px) {
        width: 80%;
        margin-bottom: 20px;
      }
    }
    .sliderContainer {
      padding-top: 40px;
      display: flex;
      @media only screen and (max-width: 1440px) {
        width: 100%;
      }
      .arrowContainer {
        display: flex;
        align-items: center;
        padding: 30px;
        @media only screen and (max-width: 980px) {
          padding: 0;
          margin-bottom: 30px;
        }
      }
      .arrowLeft {
        padding-left: 50px;
        @media only screen and (max-width: 980px) {
          position: relative;
          padding-left: 0;
          left: 5vw;
          z-index: 11;
        }
      }
      .arrowRight {
        padding-right: 50px;
        @media only screen and (max-width: 980px) {
          position: relative;
          padding-right: 0;
          left: -5vw;
          z-index: 11;
        }
      }
      .testimonial-slider {
        width: 800px;
        height: 375px;
        @media only screen and (max-width: 1440px) {
          width: 500px;
        }
        @media only screen and (max-width: 980px) {
          width: 80%;
          margin-bottom: 0;
        }
        .box {
          border: none;
          border-radius: 25px;
          box-shadow: none;
          margin-bottom: 40px;
          @media only screen and (max-width: 980px) {
            margin-bottom: 0;
          }
          p {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 600;
          }
          .contact {
            width: 100%;
            display: flex;
            justify-content: space-between;
            img {
              @media only screen and (max-width: 980px) {
                width: 20%;
              }
            }
            .contact__info {
              display: flex;
              @media only screen and (max-width: 980px) {
                width: 60%;
                justify-content: flex-end;
              }
              .contact__info_user {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-right: 20px;
                @media only screen and (max-width: 980px) {
                  margin-right: 10px;
                }
                h4 {
                  color: #19569c;
                }
              }
              .contact__userPhoto {
                max-width: 100px;
                max-height: 100px;
                @media only screen and (max-width: 980px) {
                  min-width: 35px;
                  min-height: 35px;
                }
              }
            }
          }
        }
        .box::before {
          display: none;
        }
      }
    }
    .moreTestimonials {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 70px;
      @media only screen and (max-width: 980px) {
        margin-top: 0;
      }
      h4 {
        color: #0fafe0;
      }
      img {
        margin-left: 15px;
        width: 9px;
        height: 15px;
      }
    }
  }
}
@media (max-width: 1280px) {
}
