footer {
  display: flex;
  justify-content: center;
  width: 100%;

  .footerWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;

    font-family: "Roboto";
    font-size: 20px;
    font-weight: 500;

    .topSection {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 40px;

      .getFreeSection {
        max-width: 800px;

        img {
          width: 160px;
          height: 55px;
        }

        p {
          margin-top: 30px;
          margin-bottom: 30px;
        }

        .getFreeSection__btn {
          width: 225px;
          height: 60px;
          font-size: 20px;
          line-height: 30px;
          font-weight: 600;
          background: linear-gradient(#0d8bc1, #19569c);
          border: none;
          border-radius: 4px;
          color: #fff;
          font-size: 23px;
          line-height: 30px;
          font-family: "Quicksand", sans-serif;
          font-weight: 300;
        }
      }

      .contactSection {
        max-width: 360px;
        display: flex;
        flex-direction: column;

        ul {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          li {
            display: flex;
            align-items: center;

            img {
              width: auto;
              height: auto;
              margin-right: 20px;
            }
          }
        }
      }
    }
    .devider {
      border-bottom: solid 1px #b8bcc3;
    }
    .bottomSection {
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: #b8bcc3;
      .bottomSection__social {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 20px;
        margin-top: 60px;

        img {
          width: 32px;
          height: 32px;
        }
      }

      .bottomSection__copyright {
        display: flex;
        justify-content: center;
        margin-top: 24px;

        .warranty {
          color: #19569c;
        }
      }
      .lorem {
        display: flex;
        justify-content: center;
        margin-top: 40px;
      }
    }
  }
}
