.windowsHomeTop {


  @media only screen and (max-width: 1380px) and (min-width: 981px) {
    .topBanner {
      height: 640px !important;
    }
  }

  @media only screen and (max-width: 540px) {
    margin-bottom: 60px;
  }

  .promoStamp{
    width: 320px;
    top: 210px;
    background-color: rgba(17, 17, 17, 0.6705882353);
    background-image: linear-gradient(180deg, #1a579c, transparent);
    border-radius: 3px;
    position: absolute;
    color: #fff;
    z-index: 2;
    right: 16px;
    filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5));
    font-weight: 400;
    text-align: center;
    padding: 20px 10px;

    .closeBtn {
      display: none;
      width: 30px;
      padding: 2px;
      height: 30px;
      border-radius: 50%;
      position: absolute;
      right: -6px;
      top: -10px;
      color: #1863a5;
      background-color: #fff;
      cursor: pointer;
    }


    .topPar span{
      text-transform: uppercase;
      font-weight: 700;
    }

    .middleOffer {
      text-align: left;
      padding: 15px 20px;
      padding-top: 20px;
      text-transform: uppercase;
      font-size: 0.8em;
      color: #e4e4e4;
      line-height: 20px;

      &.mobile{
        text-align: center;
        margin: 10px 0 10px 0;
        padding: 10px 20px;
        display: none;
      }
      &.image strong{
        position: relative;
      }

      &.image strong::after{
        content: '';
        width: 40px;
        height: 50px;
        background-image: url("../../../Assets/images/plus.png");
        position: absolute;
        background-size: 100%;
        background-repeat: no-repeat;
        left: 0;
        margin-top: -18px;
        margin-left: -19px;
      }

      &.image.extra.desk strong::after{
        margin-top: -38px;
      }

    }

    .button{
      background: #f09d05;
      // background: #02afde;
      border: none;
      border-radius: 4px;
      padding: 10px;
      line-height: 30px;
      font-family: "Quicksand", sans-serif;
      font-weight: 300;
      cursor: pointer;
      margin: 10px;
      font-size: 0.9em;
      box-shadow: 2px 4px 13px rgba(0, 0, 0, 0.3), 2px 2px 2px rgba(0, 0, 0, 0.1);

      &:hover{
        // background: linear-gradient(#12a4e3, #19569c);
        background: #d08801;
        // background: #03c8ff;
        box-shadow: 2px 4px 13px rgba(0, 0, 0, 0.4), 2px 2px 2px rgba(0, 0, 0, 0.25);
      }

      a {
        font-weight: 700;
        color: #fff;
      }
    }

    .endPart {
      font-size: 0.8em;
    }

    @media only screen and (max-width: 1380px) {
      top: 450px;
    }

    @media only screen and (max-width: 980px) {
      width: calc(100% - 20px);
      height: auto;
      margin: 0;
      margin-left: 10px;
      left: 0;
      background-image: linear-gradient(180deg, transparent, #1a579c);
      top: 500px;
      border-radius: 0px;

      .middleOffer {
        &.desk{
          display: none;
        }
        &.mobile{
          display: block;
        }
        // &.image strong::after{
        //   margin-top: -9px;
        //   margin-left: -343px;
        // }
      }

      .closeBtn {
        display: block;
      }

      .button{
        margin: 0 auto;
        max-width: 300px;
        margin-bottom: 16px;
      }
    }

    @media only screen and (max-width: 540px) {
      top: -80px;
      font-size: 0.9em;

      .middleOffer.mobile{
        margin: 10px 0 10px 0;
      }
      // .middleOffer.image strong::after{
      //   margin-top: -19px;
      //   margin-left: -284px;
      // }
    }

    @media only screen and (max-width: 440px) {
      top: -60px;
    }
  }

  .topBanner .promoStamp {
    @media screen and (max-width: 980px) {
      display: none;
    }
  }

  .promo2{
    width: 200px;
    top: 210px;
    position: absolute;
    left: 16px;
    filter: drop-shadow(2px 4px 6px rgba(0,0,0, 0.3));
    z-index: 200;

    &:hover{
      filter: drop-shadow(2px 4px 6px rgba(0,0,0,.6));
    }
  }

  .topBanner {
    display: flex;
    width: 100%;
    height: 750px;
    overflow: hidden;
    background-image: url("../../../Assets/images/windows/home/windows-home-top-background.png");
    background-size: cover;
    // @media only screen and (max-width: 1180px) {
    //   .promo2 {
    //     top: 205px;
    //   }
    // }
    @media only screen and (max-width: 980px) {
      height: auto;
      min-height: 425px;
      background-position-x: 50%;

      .promo2 {
        display: none;
      }

      .promo2.visible{
        display: block;
        width: 100px;
        left: 16px;
        top: auto;
        bottom: 76px;
        position: fixed;
        z-index: 1000;
      }
    }
    .ivanSection {
      position: relative;
      img {
        position: absolute;
        bottom: 0;
        left: 30px;
        max-width: none;
        width: 440px;
        height: 697px;
        @media only screen and (max-width: 1440px) {
          width: 307px;
          height: 487px;
        }
        @media only screen and (max-width: 980px) {
          width: 142px;
          height: 226px;
          left: 14vw;
        }
      }
      .ivanSection__description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 136px;
        left: 90px;
        width: 275px;
        height: 95px;
        background-color: rgba($color: #fff, $alpha: 0.9);
        border-radius: 4px;
        @media only screen and (max-width: 1440px) {
          bottom: 70px;
          left: 60px;
        }
        @media only screen and (max-width: 980px) {
          width: 137px;
          height: 45px;
          bottom: 25px;
          left: 10vw;
          z-index: 10;
        }
        h3,
        h4 {
          color: #19569c;
          @media only screen and (max-width: 980px) {
            font-size: 0.6em;
            line-height: 14px;
          }
        }
        h3 {
          @media only screen and (max-width: 980px) {
            font-weight: 600;
          }
        }
        h4 {
          @media only screen and (max-width: 980px) {
            font-weight: 300;
          }
        }
      }
    }
    .textSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      bottom: 100px;
      width: 100%;
      // z-index: 2;

      @media only screen and (max-width: 980px) {
        text-align: center;
        position: static;
        justify-content: flex-start;
        background: linear-gradient(
          180deg,
          rgba(245, 244, 249, 0.9009978991596639) 0%,
          rgba(255, 255, 255, 0.8452556022408963) 25%,
          rgba(255, 255, 255, 0) 49%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      h2 {
        color: #19569c;
        font-weight: 500;
        margin-bottom: 25px;
        text-shadow: none;
        @media only screen and (max-width: 1900px) {
          width: 50%;
          text-align: center;
        }
        @media only screen and (max-width: 1440px) {
          width: 100%;
          text-align: center;
        }
        @media only screen and (max-width: 980px) {
          width: 100%;
          margin-bottom: 10px;

          line-height: 49px;


        }
        @media only screen and (max-width: 480px) {
            margin-top: 6px;
            font-size: 1.7em;
        }
        @media only screen and (max-width: 330px) {
            margin-top: 6px;
            font-size: 1.2em;
        }
      }
      h3 {
        width: 40%;
        margin-bottom: 25px;
        text-align: center;
        color: #19569c;
        @media only screen and (max-width: 980px) {
          width: 70%;
          font-size: 1.3em;
        }
      }
      button {
        width: 300px;
        height: 60px;
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        background: linear-gradient(#0d8bc1, #19569c);
        border: none;
        border-radius: 4px;
        color: #fff;
        font-size: 23px;
        line-height: 30px;
        font-family: "Quicksand", sans-serif;
        font-weight: 300;
        cursor: pointer;
        box-shadow: 2px 4px 13px rgba(0, 0, 0, 0.4),
          2px 2px 2px rgba(0, 0, 0, 0.1);

        @media only screen and (max-width: 980px) {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 259px;
          height: 67px;
          font-size: 1.3em;
        }
        @media only screen and (max-width: 375px) {
          width: 259px;
          height: 67px;
          font-size: 1.3em;
        }
      }
      button.cta {
        // background: linear-gradient(90deg, rgb(229 148 0) 0%, rgb(255 169 11) 100%);
        background: linear-gradient(90deg, rgb(20 47 74) 0%, rgb(22 84 144) 100%);
      }
      button.ctaB {
        background: linear-gradient(90deg, rgb(20 47 74) 0%, rgb(22 84 144) 100%);
      }
      .welcomeBottomn {
        width: 40%;
        text-align: center;
        font-weight: 500;
        color: #19569c;
        position: absolute;
        bottom: 0;
        @media only screen and (max-width: 1280px) {
          width: 30%;
          bottom: 150px;
        }
        @media only screen and (max-width: 550px) {
          width: 197px;
          height: 57px;
          font-size: 1.1em;
        }
      }
    }
    .mikeSection {
      position: relative;
      img {
        position: absolute;
        bottom: 0;
        right: 20px;
        max-width: none;
        @media only screen and (max-width: 1440px) {
          width: 331px;
          height: 487px;
        }
        @media only screen and (max-width: 980px) {
          width: 160px;
          height: 236px;
          right: 15vw;
        }
      }
      .mikeSectionDescription {
        display: flex;
        cursor: pointer;
        
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 136px;
        right: 90px;
        width: 275px;
        height: 95px;
        background-color: rgba($color: #fff, $alpha: 0.9);
        border-radius: 4px;
        @media only screen and (max-width: 1440px) {
          bottom: 70px;
          right: 60px;
        }
        @media only screen and (max-width: 980px) {
          width: 137px;
          height: 45px;
          bottom: 25px;
          right: 10vw;
        }
        h3,
        h4 {
          color: #19569c;
        
          @media only screen and (max-width: 980px) {
            font-size: 0.6em;
            height: auto;
            line-height: 14px;
          }
        }
        h3 {
          @media only screen and (max-width: 980px) {
            font-weight: 600;
          }
        }
        h4 {
          color: #0000ff;
          border-bottom: 1px solid #0000ff;
          line-height: 16px;
          margin-top: 8px;
          margin-bottom: 6px;

          &:hover {
            color: #2222ff;
          }
          @media only screen and (max-width: 980px) {
            font-weight: 300;
            margin-top: 0px;
            margin-bottom: 3px;
          }
        }
        .mikeSection__arrow {
          bottom: 20px;
          @media only screen and (max-width: 1440px) {
            width: 10px;
            height: 10px;
          }
          @media only screen and (max-width: 980px) {
            width: 6px;
            height: 6px;
            bottom: 11px;
            right: 1vw;
          }
        }
      }
    }
  }
  .topBanner.roof {
    background-image: url("../../../Assets/images/roof/home/roof-home-top-backg.png");
    // margin-top: -13px;
    background-position: 50% 25%;

    @media only screen and (max-width: 980px) {
      margin-top: 0;
    }
    .ivanSection {
      img {
        width: 386px;
        height: 612px;
        left: 100px;
        @media only screen and (max-width: 1440px) {
          width: 307px;
          height: 487px;
          left: 50px;
        }
        @media only screen and (max-width: 980px) {
          width: 142px;
          height: 226px;
          left: 14vw;
        }
      }
      .ivanSection__description {
        @media only screen and (min-width: 1440px) {
          left: 190px;
        }
        @media only screen and (max-width: 980px) {
          left: 140px;
        }
        @media only screen and (max-width: 700px) {
          left: 100px;
        }
        @media only screen and (max-width: 500px) {
          left: 10vw;
        }
      }
    }

    .mikeSection > img {
      width: 416px;
      height: 612px;
      right: 100px;
      @media only screen and (max-width: 1440px) {
        width: 331px;
        height: 487px;
        right: 50px;
      }
      @media only screen and (max-width: 980px) {
        width: 160px;
        height: 236px;
        right: 15vw;
      }
    }
    .mikeSection {
      .mikeSectionDescription {
        @media only screen and (min-width: 1440px) {
          right: 190px;
        }
        @media only screen and (max-width: 980px) {
          right: 140px;
        }
        @media only screen and (max-width: 700px) {
          right: 100px;
        }
        @media only screen and (max-width: 500px) {
          right: 10vw;
        }
      }
    }
  }
}
