.productSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 80px;
  font-weight: 500;
  h2 {
    color: #19569c;
    margin-bottom: 80px;
    font-weight: 500;
    text-shadow: none;
  }

  .productTable {
    display: flex;
    flex-direction: column;
    width: 100%;
    table {
      border-spacing: 0;
      border-collapse: collapse;
    }
    tr {
      height: 70px;
      text-align: center;
      border: 1px solid lightslategray;
      border-top: none;
    }
    tr td:first-child {
      text-align: left;
      padding-left: 40px;
      border: none;
    }

    tr:nth-child(2n + 1) {
      background-color: #f7f7f7;
    }

    .tableHeader {
      background-color: black !important;
      color: #fff;
      font-weight: 900;
      td {
        @media only screen and (max-width: 1440px) {
          padding: 10px;
        }
      }
    }
  }
}
