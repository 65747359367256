.ourWorkTopSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 450px;
  background-image: url("../../../Assets/images/roof/ourWork/ourWork-top-backg.png");
  background-size: cover;
  margin-bottom: 70px;

  h2 {
    color: #19569c;
    text-shadow: none;
  }
  h3 {
    color: #19569c;
    text-shadow: none;
    margin-top: 60px;

  }

  @media only screen and (max-width: 980px) {
    background-image: url("../../../Assets/images/roof/ourWork/ourWorkTopBackground-mobile.png");
    height: 250px;
    margin-bottom: 20px;

    h2, h3 {
        display: none;
    }

  }
}
