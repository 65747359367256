.enjoyNewWindow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 40px 0 40px 0px;
  margin-bottom: 70px;
  width: 100%;
  height: 750px;
  background-image: url("../../../Assets/images/windows/home/background-enjoy.png");
  background-size: cover;
  @media only screen and (max-width: 1440px) {
    justify-content: space-between;
    height: auto;
  }
  .enjoyTxt {
    display: flex;
    flex-direction: column;
    row-gap: 70px;
    width: 30%;
    margin-right: 50px;
    @media only screen and (max-width: 1440px) {
      padding-left: 30px;
    }
    @media only screen and (max-width: 980px) {
      width: 100%;
      margin-right: 0px;
      padding-left: 0;
      align-items: center;
    }
    h2 {
      color: #19569c;
      font-weight: 500;
      text-shadow: none;
      @media only screen and (max-width: 1440px) {
        font-size: 35px;
      }
      @media only screen and (max-width: 980px) {
        width: 100%;
        text-align: center;
      }
    }

    .enjoyTxt__windows {
      display: flex;
      column-gap: 20px;
      @media only screen and (max-width: 980px) {
        column-gap: 10px;
        width: 100%;
        justify-content: center;
      }
      @media only screen and (max-width: 600px) {
        column-gap: 10px;
        width: 80%;
      }
    }
    button {
      width: 225px;
      height: 60px;
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      background: linear-gradient(#0d8bc1, #19569c);
      border: none;
      border-radius: 4px;
      color: #fff;
      font-size: 23px;
      line-height: 30px;
      font-family: "Quicksand", sans-serif;
      font-weight: 300;
      cursor: pointer;
    }
    button.cta {
      background: linear-gradient(90deg, rgb(229 148 0) 0%, rgb(255 169 11) 100%);
    }
    button.ctaB {
      background: linear-gradient(90deg, rgb(20 47 74) 0%, rgb(22 84 144) 100%);
    }
  }

  .wndType {
    width: 60%;
    &:hover {
      filter: none !important;
    }

    @media only screen and (max-width: 1440px) {
      width: 50%;
    }
    @media only screen and (max-width: 980px) {
      width: 90%;
      align-self: flex-end;
      margin-right: -4%;
    }
  }
}
.enjoyNewWindow.roof {
  justify-content: flex-start;
  @media only screen and (max-width: 980px) {
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 700px;
    background-position: center bottom;
  }
  @media only screen and (max-width: 767px) {
    height: 600px;
  }
  @media only screen and (max-width: 400px) {
    background-position: inherit;
  }
  .enjoyTxt {
    margin-top: 100px;
    margin-bottom: 100px;
    margin-left: 80px;
    min-width: 550px;
    @media only screen and (max-width: 980px) {
      margin-left: 0;
      margin-top: 0;
      min-width: 375px;
      font-size: medium;
      row-gap: 0;
      min-height: 300px;
    }
    h2 {
      color: white;
      @media only screen and (max-width: 980px) {
        width: 100%;
      }
    }
    h3 {
      color: white;
      @media only screen and (max-width: 980px) {
        width: 100%;
      }
    }
    .enjoyTxt__btns {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      width: 370px;
      @media only screen and (max-width: 980px) {
        padding: 20px;
        column-gap: 12px;
        row-gap: 12px;
      }

      .roofBtn {
        @media only screen and (max-width: 980px) {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100px;
          height: 35px;
          background: none;
          border: 2px solid white;
          border-radius: 10px;
          font-weight: 600;
          font-size: 13px;
        }
      }
      .roofBtn.active {
        background-color: white;
        color: #19569c;
      }
    }
    .enjoyTxt__windows {
      display: flex;
      flex-wrap: wrap;
      row-gap: 12px;
      column-gap: 12px;
      .roofBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 50px;
        background: none;
        border: 2px solid white;
        border-radius: 20px;
        font-weight: 600;
      }
      .roofBtn.active {
        background-color: white;
        color: #19569c;
      }
    }
  }
}
