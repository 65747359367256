.windowsIntro {
  display: flex;
  height: 700px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-image: url("../../../Assets/images/windows/ourWorks/ourWork-bottom-intro.png");
  background-size: 100%;
  margin-top: -3px;
  @media only screen and (max-width: 1440px) {
    height: 550px;
  }
  @media only screen and (max-width: 980px) {
    justify-content: flex-start;
    height: 700px;
    background-size: cover;
  }

  .windows__content {
    max-width: 1440px;
    width: 100%;

    @media only screen and (max-width: 1440px) {
      padding-left: 60px;
    }
    @media only screen and (max-width: 980px) {
      padding-top: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-left: 0;
    }
    h2 {
      color: #19569c;
      margin-bottom: 30px;
      font-weight: 500;
      text-shadow: none;
      @media only screen and (max-width: 980px) {
        text-align: center;
      }
    }
    h3 {
      font-weight: 300;
      color: #19569c;
      @media only screen and (max-width: 980px) {
        text-align: center;
        padding: 10px;
      }
    }
    button {
      width: fit-content;
      min-width: 255px;
      height: 60px;
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      background: linear-gradient(#0d8bc1, #19569c);
      border: none;
      border-radius: 4px;
      color: #fff;
      font-size: 23px;
      line-height: 30px;
      font-family: "Quicksand", sans-serif;
      font-weight: 300;

      margin-top: 60px;
    }
  }
}
