.sizesSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 80px;
  padding: 15px;
  h2 {
    margin-top: 60px;
    color: #19569c;
    font-weight: 500;
    text-shadow: none;
  }
  h3 {
    width: 100%;
    font-weight: 500;
    text-align: center;
    font-size: large;
    margin-left: 15%;
    margin-right: 15%;
    padding: 10px;
    box-sizing: border-box;
  }
  .SizesContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 60px;
    @media only screen and (max-width: 980px) {
      justify-content: space-around;
      align-items: baseline;
      flex-wrap: wrap;
    }
    .SizesContainer__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      @media only screen and (max-width: 980px) {
        width: 45%;
        flex: none;
      }
      .SizesContainer__item_img {
        @media only screen and (max-width: 980px) {
          max-width: none;
          max-width: 250px;
        }
      }
      .SizesContainer__item_number {
        margin-top: 15px;
        margin-bottom: 15px;
        color: #19569c;
        font-size: 25px;
        font-weight: 600;
      }
      p {
        margin-bottom: 10px;
        font-weight: 600;
      }
    }
  }
}
