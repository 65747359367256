.windowsHowItWorksHome {
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    font-weight: 500;
    text-shadow: none;
    color: #19569c;
  }
  .stepSection {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 50px;
    @media only screen and (max-width: 980px) {
      flex-direction: column;
      align-items: center;
    }
    .stepSection__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 480px;
      @media only screen and (max-width: 1440px) {
        width: 30%;
      }
      @media only screen and (max-width: 980px) {
        width: 100%;
      }
      img {
        width: 355px;
        height: 225px;
        margin-bottom: 40px;
        @media only screen and (max-width: 1440px) {
          width: 284px;
          height: 180px;
          margin: 20px;
        }
        @media only screen and (max-width: 980px) {
          width: 284px;
          height: 180px;
        }
      }
      h3 {
        color: #19569c;
        font-weight: 600;
        margin-bottom: 40px;
        text-align: center;
        @media only screen and (max-width: 980px) {
          margin-bottom: 0;
        }
      }
      p {
        text-align: center;
        color: black;
        font-weight: 600;
        @media only screen and (max-width: 1440px) {
          margin: 10%;
        }
      }
    }
  }
  .getStartBtn {
    margin-top: 40px;
    button {
      width: 225px;
      height: 60px;
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      background: linear-gradient(#0d8bc1, #19569c);
      border: none;
      border-radius: 4px;
      color: #fff;
      font-size: 23px;
      line-height: 30px;
      font-family: "Quicksand", sans-serif;
      font-weight: 300;
      cursor: pointer;
    }
  }
  .getStartBtn.cta {
    button {
      // background: linear-gradient(90deg, rgb(229 148 0) 0%, rgb(255 169 11) 100%);
      background: linear-gradient(90deg, rgb(20 47 74) 0%, rgb(22 84 144) 100%);
    }
  }
  .getStartBtn.ctaB {
    button {
      background: linear-gradient(90deg, rgb(20 47 74) 0%, rgb(22 84 144) 100%);
    }
  }
}


