.ourColection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
  margin-bottom: 90px;
  @media only screen and (max-width: 980px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  h2 {
    color: #19569c;
    margin-bottom: 90px;
    font-weight: 500;
    text-shadow: none;
    @media only screen and (max-width: 980px) {
      width: 100%;
      text-align: center;
      margin-bottom: 30px;
    }
  }
  .subTitle {
    margin-bottom: 20px;
    @media only screen and (max-width: 980px) {
      margin-left: 20px;
      margin-right: 20px;
      text-align: center;
    }
  }
  .gridContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 70px;
    margin-left: 80px;
    margin-right: 80px;
    @media only screen and (max-width: 980px) {
      margin-left: 20px;
      margin-right: 20px;
      justify-content: center;
    }
    .grid__Item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 30%;
      padding: 40px 28px;
      border-right: solid 1px gray;
      @media only screen and (max-width: 980px) {
        width: 45%;
        border-right: none;
        padding: 18px 5px;
      }

      h3 {
        color: #19569c;
        margin-top: 20px;
        text-align: center;
      }
    }
    .grid__Item:nth-child(2n) {
      border-right: solid 1px gray;
      @media only screen and (max-width: 980px) {
        border-left: solid 1px gray;
        border-right: none;
      }
    }
    .grid__Item:nth-child(3n) {
      border-right: none;
    }
  }
}
