@import "./bootstrap-grid";

input,
button,
select,
textarea {
  font-family: "Lato", sans-serif !important;
}

.container {
  // width: 1300px;
  max-width: 100%;
  margin: 0 auto;
  padding-right: 15px !important;
  padding-left: 15px !important;
}

img {
  max-width: 100%;
}

.mobile-none {
  @media (min-width: 300px) and (max-width: 450px) {
    display: none !important;
  }
}

.iPad-display {
  display: none !important;
  @media (min-width: 768px) and (max-width: 1024px) {
    display: block !important;
  }
}

.desktop-none {
  display: none !important;
  @media (min-width: 300px) and (max-width: 450px) {
    display: block !important;
  }
}

#mobileBanner {
  height: 300px;
  position: relative;
  background-size: cover;
  background-position-x: right;
  @media screen and (max-width: 480px) {
    height: 254px;
    position: relative;
    background-size: cover;
    background-position-x: right;
    background-size: 160%;
    background-repeat: no-repeat;
  }
  @media screen and (max-width: 320px) {
    height: 226px;
  }
}

#home-banner {
  min-height: 670px;
  height: 90vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: right;
  // clip-path: polygon(0 0, 100% 0, 100% 92%, 0% 100%);
  @media (min-width: 768px) and (max-width: 1024px) {
    top: 0 !important;
  }
  @media screen and (max-width: 1399px) {
    position: relative;
    top: 0px;
  }
  margin-bottom: 210px;
  @media screen and (max-width: 1064px) {
    margin-bottom: 220px;
  }

  @media screen and (max-width: 500px) {
    min-height: unset;
    background-position: center;
  }

  @media screen and (max-width: 534px) {
    margin-bottom: 380px;
    background-size: 100% 62%;
    background-position-y: 0;
  }

  &.estimate-header-contain {
    height: 810px;
    margin-bottom: 100px;
    @media screen and (max-width: 1399px) {
      height: 840px;
    }
    @media screen and (max-width: 1065px) {
      height: auto;
    }
  }

  .home-banner-content {
    position: absolute;
    left: 0px;
    bottom: -168px;
    width: 1328px;
    max-width: 100%;
    // min-height: 336px;
    background-color: #243048;
    display: flex;
    align-items: center;
    padding: 20px 120px;
    @media screen and (max-width: 1770px) {
      width: 1178px;
      padding: 40px 60px;
    }

    @media screen and (max-width: 1610px) {
      width: 1127px;
    }

    @media screen and (max-width: 1006px) {
      flex-direction: column;
    }

    @media screen and (max-width: 878px) {
      padding: 40px 60px;
    }

    @media screen and (max-width: 534px) {
      //bottom: -318px;
      bottom: -210px;
      min-height: 500px;
    }

    .left {
      width: 50%;
      @media screen and (max-width: 1006px) {
        width: 100%;
      }
    }

    .right {
      flex: 1;
      margin-left: 100px;
      @media screen and (max-width: 1006px) {
        margin-left: 0px;
      }
    }

    h1 {
      color: #ffffff;
      font-family: sans-serif;
      font-size: 44px;
      font-weight: 700;
      line-height: 56px;
      letter-spacing: -1.15px;
      line-height: unset;
      @media screen and (max-width: 1610px) {
        font-size: 33px;
      }

      @media screen and (max-width: 417px) {
        font-size: 20px;
      }
    }

    span {
      &.sub-head {
        color: #dbddf3;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 0.8px;
        line-height: unset;
      }
    }

    p {
      font-size: 22px;
      font-weight: 300;
      line-height: 26px;
    }

    a {
      &.btn.blue-btn {
        box-shadow: none;
        border-bottom: 0px;
        font-size: 15px;
      }
    }

    .button-desktop {
      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    .button-mobile {
      @media screen and (min-width: 600px) {
        display: none;
      }
    }

    .banner-testimonial {
      position: absolute;
      right: -420px;
      width: 380px;
      bottom: 10px;
      text-align: center;
      @media screen and (max-width: 1570px) {
        display: none;
      }
      p {
        color: #243048;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: -0.09px;
        margin: 0px;
      }
      .author {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        .author-image {
          img {
            margin-right: 10px;
          }
        }

        h5 {
          color: #243048;
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 1.6px;
          margin: 0px;
        }
      }

      &:before {
        content: '"';
        position: absolute;
        top: 0px;
        left: -30px;
        opacity: 0.05;
        color: #243048;
        font-family: cursive;
        font-size: 110px;
      }
    }
  }
}

#mobile-home-banner {
  width: 100%;
  background-color: #243048;
  margin-top: -5px;
  .mobile-banner-content {
    display: flex;
    align-items: center;
    padding: 0 20px 10px;
    text-align: center;
    .left {
      width: 50%;
      @media screen and (max-width: 1006px) {
        width: 100%;
      }
    }

    h1 {
      color: #ffffff;
      font-family: sans-serif;
      font-size: 44px;
      font-weight: 700;
      line-height: 56px;
      letter-spacing: -1.15px;
      line-height: unset;
      @media screen and (max-width: 1610px) {
        font-size: 33px;
      }

      @media screen and (max-width: 417px) {
        font-size: 20px;
      }
    }

    span {
      &.sub-head {
        color: #dbddf3;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 0.8px;
        line-height: unset;
      }
    }
  }
  .slick-slide img {
    width: 100vw;
    max-height: 250px;
  }
}
.careers {
  .container {
    .row {
      .description {
        background: #f8f8f8;
        padding-top: 40px !important;
        @media screen and (max-width: 767px) {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
      }
      .description::after {
        position: absolute;
        top: 0;
        right: 0px;
        width: 1000px;
        height: 100%;
        background-color: transparent;
        box-shadow: 1000px 0px #f8f8f8;
        content: "";
        z-index: -1;
      }
    }
  }
  .coreValues {
    ul {
      font-size: 25px;
      padding-left: 20px;
      line-height: 30px;
      color: #313131;
      font-family: "Quicksand", sans-serif;
      font-weight: 300;
      list-style-type: disc;
      li {
        text-align: left;
      }
    }
    a {
      background: linear-gradient(90deg, #46bfee, #29dce0);
    }
  }
  .qualitiesDescription {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 90%,
      rgba(255, 255, 255, 1) 97%,
      rgba(245, 245, 245, 1) 100%
    );
    li {
      color: #5a6a76;
    }
  }
  .quailitiesGallery {
    box-shadow: 5px 5px 10px -2px rgba(0, 0, 0, 0.21);
  }
  .quailitiesGallery,
  .qualitiesDescription {
    padding: 2rem !important;
    @media screen and (max-width: 767px) {
      background: #fff;
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }
    ul {
      font-size: 20px;
      font-weight: 700;
      line-height: 20pt;
      list-style-type: disc;
      li {
        text-align: left;
      }
    }
  }
  .qualitiesDescription {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 767px) {
      h2 {
        text-align: center;
      }
    }
  }
  .quailitiesGallery-1 {
    box-shadow: 5px 6px 8px -1px rgba(0, 0, 0, 0.21);
  }
  .quailitiesGallery-1,
  .quailitiesGallery {
    @media screen and (max-width: 600px) {
      padding: 0 !important;
    }
    small {
      font-size: 18px;
      font-weight: 700;
      @media screen and (max-width: 767px) {
        padding: 15px;
        padding-bottom: 0;
      }
    }
  }
  h2,
  h3,
  .title-submit,
  .title-blue-border {
    text-shadow: 2px 2px #d8d8d8;
  }
}
.careers {
  // .container {
  //   width: 2200px;
  // }
  #submit_resume {
    margin: 0 40px;
    justify-content: center;
    @media screen and (max-width: 1440px) {
      margin: unset;
    }
    input {
      box-shadow: 0px 0px 4px 0px #00000059;
    }
  }
}
#inner-banner {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &.careers {
    background-image: url("../../../Assets/images/banner-bg-career.jpg");
    height: 500px;
    @media screen and (max-width: 1600px) {
      height: 450px;
    }
    .inner-banner-content {
      h1 {
        // margin-top: 14rem;
        font-weight: 100;
      }
    }
    @media screen and (max-width: 1024px) {
      height: unset;
      .inner-banner-content {
        h1 {
          margin-top: unset;
        }
      }
    }
  }
  .inner-banner-content {
    display: flex;
    min-height: 329px;
    align-items: center;
    justify-content: center;
    text-align: center;
    h1 {
      font-size: 60px;
      color: #fff;
    }

    p {
      opacity: 0.8;
      color: #ffffff;
      font-size: 22px;
      font-weight: 400;
      line-height: 30px;
    }
  }
}
.banner-content {
  width: 1300px;
  max-width: 100%;
  // margin: 0 auto;
  padding-top: 130px;
  @media screen and (max-width: 1337px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: 1065px) {
    flex-direction: column-reverse;
    padding-top: 20px;
  }

  @media screen and (max-width: 999px) {
    padding-bottom: 96px;
  }

  .left {
    width: 90%;
    span {
      &.sub-head {
        color: #bdcfdd;
        font-size: 20px;
        font-weight: 400;
        line-height: 100px;
        text-transform: uppercase;
        letter-spacing: 4px;
        @media screen and (max-width: 768px) {
          line-height: 47px;
          text-align: center;
          width: 100%;
          display: inline-block;
        }
      }
    }

    h1 {
      color: #ffffff;
      font-size: 58px;
      font-weight: 600;
      line-height: 86px;
      letter-spacing: 4.25px;
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 7rem;
      @media screen and (max-width: 999px) {
        line-height: 69px;
        margin-bottom: 20px;
      }

      @media screen and (max-width: 1024px) {
        font-size: 55px;
        line-height: 56px;
        letter-spacing: 1.25px;
        margin-bottom: 30px;
        margin-left: 0px;
        text-align: center;
      }
    }

    p {
      color: #ffffff;
      font-size: 26px;
      font-weight: 300;
      line-height: 32px;
      letter-spacing: -0.26px;
      strong {
        font-weight: 700;
        letter-spacing: -0.26px;
      }

      a {
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  .right {
    .video-holder {
      display: flex;
      border: solid 21px rgba(0, 0, 0, 0.19);
    }
  }

  .bottom-banner {
    background-color: #224463;
    opacity: 0.8;
    height: 80px;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 2;
    left: 0;
    @media screen and (max-width: 980px) {
      height: 120px;
    }
  }
  .bottom-banner-text {
    color: #ffffff;
    position: absolute;
    text-align: center;
    bottom: 0;
    font-size: 2rem;
    font-weight: bold;
    width: 100%;
    line-height: 15px;
    z-index: 2;
    left: 0;
    @media screen and (max-width: 980px) {
      line-height: 30px;
    }
  }

  &.estimate {
    text-shadow: 3px 1px 8px #404040;
    .right {
      @media screen and (max-width: 670px) {
        width: 100%;
      }
    }

    .left {
      h1 {
        font-size: 58px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -1.45px;
        @media screen and (max-width: 450px) {
          font-size: 38px;
          line-height: 50px;
        }
      }

      p {
        opacity: 0.8;
        color: #ffffff;
        font-size: 22px;
        font-weight: 400;
        line-height: 30px;
        margin-top: 30px;
        @media screen and (max-width: 450px) {
          font-size: 21px;
          margin-bottom: 25px;
        }
      }
    }
    .contact-form {
      // margin-top: -50px;
      border-radius: 10px;
      background-color: #ffffff;
      width: 540px;
      max-width: 100%;
      h3 {
        text-align: center;
        color: #384660;
        font-size: 30px;
        font-weight: 700;
        letter-spacing: -0.75px;
        padding-top: 40px;
        padding-bottom: 21px;
      }
      .estimate-inputs {
        padding-left: 54px;
        padding-right: 54px;
        margin-bottom: 44px;
        @media screen and (max-width: 444px) {
          padding-left: 24px;
          padding-right: 24px;
        }
        .input-holder {
          position: relative;
          margin-bottom: 10px;
          svg {
            position: absolute;
            top: 14px;
            left: 14px;
            height: 26px;
            z-index: 9;
            fill: #96d3ff;
            font-size: 24px;
            font-weight: 400;
            letter-spacing: -0.6px;

            @media screen and (max-width: 670px) {
              top: 0px;
              left: 8px;
            }
          }
          input {
            padding-left: 68px;
            color: #384660;
            font-size: 20px;
            font-weight: 400;
            letter-spacing: -0.5px;
            border-left: 0px;
            border-right: 0px;
            border-top: 0px;
            border-bottom: solid 2px #e3e9f6;

            @media screen and (max-width: 670px) {
              font-size: 14px;
              padding-left: 58px;
            }
          }

          .error-message {
            display: none;
            color: #ef3a3a;
            text-align: right;
            width: 100%;
          }

          &.invalid {
            i {
              color: #ef3a3a;
            }

            input {
              border-bottom: solid 2px #ef3a3a;
            }

            .error-message {
              display: inline-block;
            }
          }
        }
      }

      .submit-btn {
        button,
        .estimate-form-submit {
          border-radius: 0 0 10px 10px;
          background-color: #1d3c5a;
          background-image: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.16) 0%,
            rgba(255, 255, 255, 0.16) 100%
          );
          color: #ffffff;
          font-size: 20px;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 4px;
          padding: 33px 30px;
          border: 0px;
        }
      }

      @media screen and (max-width: 670px) {
        width: 100%;
        h3 {
          font-size: 24px;
        }
      }
    }
    .contact-form-2 {
      background-color: rgba(0, 0, 0, 0.47);
      margin-top: 1rem;
      width: 400px;
      padding-bottom: 1px;
      margin-left: 7rem;
      // height: 277px;
      // width: 310px;
      max-width: 100%;
      @media screen and (max-width: 1024px) {
        width: 100%;
        margin-left: 0px;
      }
      h3 {
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        font-size: 30px;
        font-weight: 700;
        padding-top: 20px;
        padding-bottom: 20px;
        margin: 0;
      }
      .estimate-inputs {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 20px;
        @media screen and (max-width: 444px) {
          padding-left: 24px;
          padding-right: 24px;
        }
        .input-holder {
          position: relative;
          margin-bottom: 10px;
          svg {
            position: absolute;
            top: 14px;
            left: 14px;
            height: 26px;
            z-index: 9;
            fill: #96d3ff;
            font-size: 24px;
            font-weight: 400;
            letter-spacing: -0.6px;
          }
          input {
            padding-left: 10px;
            color: #384660;
            font-size: 20px;
            font-weight: 400;
            letter-spacing: -0.5px;
            border-left: 0px;
            border-right: 0px;
            border-top: 0px;
            border-bottom: solid 2px #e3e9f6;
          }

          .error-message {
            display: none;
            color: #ef3a3a;
            text-align: right;
            width: 100%;
          }

          &.invalid {
            i {
              color: #ef3a3a;
            }

            input {
              border-bottom: solid 2px #ef3a3a;
            }

            .error-message {
              display: inline-block;
            }
          }
        }
      }

      .submit-btn {
        button,
        .estimate-form-submit {
          width: 50%;
          background-color: #e30057;
          color: #ffffff;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 4px;
          padding: 10px 10px;
          border: 0px;
        }
      }
    }
  }
}

.block-d {
  text-align: left;
  .note {
    font-weight: 600 !important;
    margin-top: 15px;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: #5a6a76;
  }
  form {
    text-align: center;
  }
  .title-submit {
    margin: 10px auto;
    display: block;
    text-align: center;
    font-weight: 600;
    font-size: 1.25rem;
  }

  .paragraph_cont{
    width: 100%;
    display: flex;
    gap: 40px;
  }

  .paragraph {
    font-size: 1rem;
    text-align: left;
    color: #5a6a76;
    font-weight: 400;
    line-height: 28px;
    font-family: "Lato", sans-serif;
    position: relative;
    overflow: hidden;
  }
  .videoContainer{
    width: 60%;
    display: flex;
  }
  .dots {
    width: 58%;
    position: absolute;
    bottom: -170px;
  }
}
.bg-grey {
  background: #ebeced;
}
.bg-white {
  background: #fff;
}
.block-c {
  line-height: 18pt;
  .title-blue-border {
    border-bottom: 2px solid #6dc0e7;
    font-size: 20px;
  }
  .title-2 {
    font-size: 20px;
  }
}
.rounded {
  border-radius: 10px;
}
.border-shadow {
  box-shadow: 1px 1px 10px #bdbdbd;
}

.btn-custom {
  text-align: center;
  padding: 1rem !important;
  border-radius: 5px;
  color: white;
  display: inline-block;
  cursor: pointer;
  background: #00aede;
  // color: #5a6a76;
  font-weight: 600;
  &.blue {
    color: #1986f9;
  }
  &.mod {
    width: 50%;
    margin: auto;
  }
}

.careers h2 {
  color: #192e44;
  font-family: "arial", sans-serif;
  font-weight: 700;
  font-size: 30px;
}

.form-cont {
  padding: 20px;
}

.main-form {
  background-color: rgba(255, 255, 255, 0.95);
  padding: 5px 20px 10px;
}

input,
button,
select,
textarea {
  padding: 10px;
  color: #333333;
  font-family: Roboto;
  width: 100%;
  border: 1px solid black;
}

.main-form h2 {
  font-weight: 700;
}

input[type="submit"] {
  width: 100%;
  background-color: #f99419;
  color: #fff;
  border: none;
  font-size: 16px;
  padding: 12px 0;
}

label {
  display: block;
}

.right-btn {
  padding-right: 50%;
}

.careers .block-b:first-child {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.careers .block-c {
  .bg-grey,
  .bg-white {
    border-radius: 0 0 15px 0;
  }
}
.inner-banner-content{
  .container {
    margin-top: 15px;
    h1{
      text-shadow: 2px 2px 5px #1d3c5a !important;
      line-height: 46px;
    }
  }

}
@media only screen and (max-width: 767px) {
  .inner-banner-content{
    background-color: rgb(255 255 255 / 43%);
    .container {
      padding: 20px;
      margin-top: 45px;
      h1{
        text-shadow: 1px 1px 0px #ffffff6b !important;
        color: #1d3c5a !important;
        line-height: 46px;
      }
    }
  
  }
  .careers .block-c .row.p-3.text-center .col-12 {
    display: flex;
    flex-direction: column;
  }
  .careers {
    .coreValues {
      .submitResume {
        justify-content: center;
        display: flex;
      }
    }
  }
  .careers .block-c .row.p-3.text-center .col-12 small {
    font-size: 18px;
  }
  .hide-on-mob {
    display: none;
  }

  .header .Call-now p span {
    display: none;
  }

  .right-btn {
    padding-right: 0;
  }

  .left-btn {
    padding-left: 0;
  }

  .form-cont {
    padding: 0 !important;
  }

  .main-form h2 {
    font-size: 26px;
  }

  .ls-layers p {
    font-size: 14px;
  }

  .banner {
    height: 560px;
  }

  .fullscreen-img {
    display: block;
    margin: 0px;
    width: auto;
  }

  .caro {
    margin-top: 10px;
  }

  .main-bnr-txtHldr a:last-child {
    padding: 13px 0px;
  }

  .main-bnr-txtHldr a:last-child img {
    width: 100%;
  }

  .financeitpage-text {
    padding: 50px 20px;
  }

  .carousel-inner {
    margin-top: 42% !important;
  }

  .main-header .hidden-xs:nth-child(1) {
    display: block !important;
  }

  .top-header .col-md-6:first-child,
  .top-header .social {
    display: none !important;
  }

  .finance-text {
    float: left;
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
  }

  .financeit-btn {
    color: #fff;
    float: left;
    margin: 4px 0 4px 6px;
    text-align: center;
  }

  .financeit-btn img {
    width: 85%;
  }
}
@media only screen and (max-width: 1180px) {

  .block-d {
    .paragraph_cont{
      flex-direction: column;
      width: auto;
    }
    .paragraph {
      flex-direction: column;
      width: auto;
    }

    .videoContainer{
      width: auto;
      display: flex;
      flex-direction: column;

      div {
        width: auto !important;
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .main-bnr-txtHldr p {
    font-size: 48px !important;
  }
  .main-bnr-txtHldr p.sml {
    font-size: 31px !important;
  }
  .estimation .estimate .image img {
    height: 600px !important;
  }
  form {
    margin-top: -620px;
  }

  .estimation .estimate form input[type="submit"] {
    padding: 13px 27px;
    font-size: 21px;
    float: left;
  }

  form {
    margin-top: 30px;
  }

  .estimation .estimate .image img {
    display: none;
  }




  .wufoo input.text,
  .wufoo textarea.textarea,
  .wufoo input.file,
  .wufoo select.select {
    width: 95%;
  }
}

.title-h2 {
  font-size: 28px;
  line-height: 33px;
}
.blue {
  color: #00b1e3;
}
#submit_resume {
  input[type="submit"] {
    background: linear-gradient(90deg, #46bfee, #29dce0);
  }
  input,
  textarea {
    border: 1px solid #c3c3c3;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    box-shadow: 1px 1px 2px #e6e6e6;
  }
  label {
    color: #999;
    cursor: pointer;
    padding: 5px 0;
    border: 1px solid #c3c3c3;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 35%);
    font-family: "Lato", sans-serif;
    font-weight: 500;
    font-size: 16px;
  }
  input[type="file"] {
    display: none;
  }
  ::-webkit-input-placeholder,
  :-moz-placeholder,
  ::-moz-placeholder,
  :-ms-input-placeholder {
    text-align: center;
  }
}

.para-footer {
  font-size: 24px;
  color: black;
  font-weight: 600;
}
