.bold-red,
.required {
  color: red;
}
.bathroomBanner {
  background-image: url("./thumbnail_AGM-Banner-Image.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin-bottom: 50px;
  .inner-bathroom-content {
    padding: 30px;
    padding-top: 10px;
    @media (max-width: 500px) {
      padding: 0;
    }
  }
  #formLogo {
    width: 300px;
    margin-top: 50px;
    margin-left: 50px;
    @media (max-width: 769px) {
      width: 150px;
      margin-top: 30px;
      margin-left: 30px;
    }
  }
}
.inner-bathroom-content {
  display: flex;
  min-height: 329px;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.bathroomTitle {
  font-size: 40px;
  color: #fff;
  margin-bottom: 10px;
  margin-top: 5px;
  @media (max-width: 769px) {
    line-height: unset;
  }
  @media (max-width: 500px) {
    font-size: 30px;
  }
}
.bathroomDescription {
  color: #fff;
}
@media (max-width: 769px) {
  #leftBathroomFrom {
    .MuiGrid-grid-xs-true {
      padding-right: 0;
    }
  }
}
#bathroomForm {
  padding: 0 60px;
  .houseInfo {
    label {
      margin-bottom: 15px;
      margin-top: 20px;
    }
  }
  .MuiFormControlLabel-root {
    margin-left: 0;
  }
  #bathroomUpload {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    padding: 5px;
    .MuiTypography-root {
      word-break: break-all;
    }
  }
  #bathroomUpload:hover {
    border: 1px solid #000;
  }
  #bathroomUpload {
    .MuiButton-contained {
      background-color: #43c4e4;
      color: white;
    }
  }
  #leftBathroomFrom {
    .MuiGrid-grid-xs-true {
      padding-right: 40px;
      @media (max-width: 599px) {
        padding-right: 0;
      }
    }
  }
  #ageHome {
    .MuiFormControl-root {
      width: 95%;
      @media screen and (max-width: 599px) {
        width: 100%;
      }
    }
  }
  .required {
    color: red;
  }
}
.thankyouBathroom {
  font-size: 40px;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
}
#bathroomType {
  font-weight: 900;
}
