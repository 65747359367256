.topDoors {
  display: flex;
  justify-content: center;
  column-gap: 80px;
  width: 100%;
  height: 70px;
  background-color: #19569c;
  @media only screen and (max-width: 980px) {
    background-color: #fff;
    flex-wrap: wrap;
    height: 8rem;
    column-gap: 5%;
  }
  .topDoors__item {
    display: flex;
    justify-content: center;
    align-items: center;
    row-gap: 40px;
    color: #fff;
    padding-left: 54px;
    padding-right: 7px;
    margin-bottom: 3px;
    @media only screen and (max-width: 1440px) {
      row-gap: 20px;
      justify-content: center;
      width: auto;
      padding-left: 23px;
    }
    @media only screen and (max-width: 980px) {
      background-color: #19569c;
      width: 40%;
      margin-bottom: 20px;
      padding-left: 4%;
      font-size: 12px;
      justify-content: flex-start;
    }

    a {
      display: flex;
      align-items: center;
      @media only screen and (max-width: 980px) {
        margin-left: 20%;
      }
      @media only screen and (max-width: 650px) {
        margin-left: 5%;
      }
      @media only screen and (max-width: 500px) {
        margin-left: 0px;
      }
      img {
        margin-right: 7px;
        @media only screen and (max-width: 1440px) {
          row-gap: 20px;
          width: 35px;
          height: 35px;
        }
        @media only screen and (max-width: 980px) {
          row-gap: 10px;
          align-self: baseline;
          justify-content: flex-start;
          padding: 5px;
        }
      }
    }
    &.active {
      background-color: rgb(70 191 238);
      @media only screen and (min-width: 980px) {
        // height: 75px;
      }
    }
  }
}
.topDoors_Wrapper {
  display: flex;
  justify-content: center;
  background-color: #19569c;
  height: 70px;
  @media only screen and (max-width: 980px) {
    height: 112px;
  }
  .topDoors.roof {
    column-gap: 0;
    justify-content: space-around;
    max-width: 1440px;
    .topDoors__item {
      margin-bottom: 0;
      font-size: large;
      @media only screen and (max-width: 1440px) {
        font-size: medium;
        padding-left: 5px;
      }
      @media only screen and (max-width: 980px) {
        width: 32%;
        margin-bottom: 16px;

        font-size: 12px;
        justify-content: flex-start;
        padding-right: 0;

        &.item3 {
          right: -8%;
          top: -10px;
          position: relative;
          width: 39%;
          margin-right: 27px;
        }
        &.item4 {
          left: -8%;
          top: -10px;
          position: relative;
          width: 39%;
          margin-left: 27px;
        }
      }
      a {
        @media only screen and (max-width: 900px) {
          margin-left: 10%;
        }
        @media only screen and (max-width: 800px) {
          margin-left: 0;
          font-size: 14px;
          line-height: 15px;
        }
        @media only screen and (max-width: 375px) {
          font-size: 12px;
        }
        img {
          align-self: center;
        }
      }
    }
  }
}
