.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#chatBox {
  position: fixed;
  bottom: -17px;
  height: 81px;
  width: 386px;
  filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 4px 10px);
  right: 210px;
  z-index: 200;
}

#chatBox.mobile {
  bottom: 175px;
  filter: none;
  right: 2px;
  height: 85px;
  width: 90px;
  z-index: 200;
}

#chatBox.open {
  height: 485px;
}
#chatBox.mobile.open {
  width: 100%;
  z-index: 1000000;
  right: 0px;
  bottom: -16px;
}
